export default [
  {
    key: 'name',
    label: 'field.name',
    type: 'text',
    operator: '%',
  },
  {
    key: 'phone',
    label: 'field.phoneNumber',
    type: 'text',
    operator: '%',
  },
  {
    key: 'username',
    label: 'field.username',
    type: 'text',
    operator: '%',
  },
  {
    key: 'isEnable',
    label: 'field.status',
    rules: 'required',
    type: 'radio',
    cast: 'boolean',
    options: [
      { text: 'status.active', value: '1' },
      { text: 'status.inactive', value: '0' },
    ],
  },
]
