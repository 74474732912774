export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  // {
  //   key: 'profilePicture',
  //   sortable: false,
  //   sortField: '',
  //   label: 'field.profilePicture',
  //   tdClass: 'text-center',
  // },
  {
    key: 'name',
    sortable: false,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'username',
    sortable: false,
    sortField: 'username',
    label: 'field.username',
  },
  {
    key: 'phone',
    sortable: false,
    sortField: '',
    label: 'field.phoneNumber',
  },
  {
    key: 'ipaddress',
    sortable: false,
    sortField: '',
    label: 'field.ipAddress',
  },
  {
    key: 'lastActiveAt',
    sortable: false,
    sortField: '',
    label: 'field.lastActiveDate',
  },
  {
    key: 'status',
    sortable: false,
    sortField: '',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
